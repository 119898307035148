@font-face {
  font-family: 'Cairo-Regular';
  src: url('./assets/fonts/Cairo-Regular.ttf') format('truetype');
}
* {
  font-family: 'Cairo-Regular' !important;
}

body {
  margin: 0;
  font-family: sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100%;
  background: #fff;
}

#detail > div {
  height: 100%;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

main {
  padding: 25px;
  background: #161c24;
  height: 100%;
}

html {
  height: 100%;
  width: 100%;
}

#root {
  height: 100%;
  width: 100%;
}

.MuiBox-root {
  height: 100%;
}

header {
  width: 100%;
  height: 80;
  display: flex;
  justify-content: space-between;
}

header > div {
  box-shadow: 0px 4px 5px #282a355c !important;
}

.MuiPaper-root {
  box-shadow: 0px 4px 5px #282a355c !important;
}
